import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledContent,
  StyledContentMarkdown,
  StyledPageTitle
} from './styled';

const Content = ({ title, content }) => {
  return (
    <StyledContent>
      <StyledWrapper>
        <StyledPageTitle>{title}</StyledPageTitle>
        <StyledContentMarkdown>
          <MarkdownPreview source={content} />
        </StyledContentMarkdown>
      </StyledWrapper>
    </StyledContent>
  );
};

export default Content;
