import React from 'react';
import Helmet from 'react-helmet';
import Content from 'components/Content';

const PrivacyPolicy = ({ page }) => {
  const { description, title, content } = page;
  return (
    <React.Fragment>
      <Content title={title} content={content} />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/br/privacy-policy",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
