import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PrivacyPolicy from 'templates/PrivacyPolicy/index';

const PrivacyPolicyPage = ({
  data: {
    ateliware: { page, headerMenu, footerMenu }
  },
  pageContext
}) => {
  const { locale } = pageContext;
  const { seo } = page;

  return (
    <React.Fragment>
      <SEO
        meta_title={
          locale == 'pt'
            ? seo?.metaTitle
            : 'ateliware | privacy policy'
        }
        meta_description={
          locale == 'pt'
            ? seo?.metaDescription
            : 'We are committed to ensuring your privacy. This privacy policy describes our practices regarding the collection, use, sharing and handling of information.'
        }
        og_title={seo?.ogTitle}
        og_description={
          locale == 'pt'
            ? seo?.ogDescription
            : 'More than a supplier that understands technology, here at ateliware we transform and simplify business. We are a software studio that develops handmade products and solutions.'
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <PrivacyPolicy page={page} />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={page.title}
      />
    </React.Fragment>
  );
};

export const PrivacyPolicyQuery = graphql`
  query PrivacyPolicyPageQuery(
    $idPage: ID!
    $locale: [GCMS_Locale!]!
  ) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        description
        title
        content
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogType
          ogDescription
          ogImage {
            url
          }
        }
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;
