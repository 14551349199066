import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledPageTitle = styled.h2`
  font-family: ${fonts.primary};
  font-size: 60px;
  font-weight: 700;
  color: ${colors.gray400};

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 420px) {
    font-size: 32px;
  }
`;

export const StyledContent = styled.div`
  display: block;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
`;

export const StyledContentMarkdown = styled.div`
  a {
    color: ${colors.red300};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    height: 1px;
    margin-left: -50px;
    background-color: ${colors.gray700};
    content: '';
  }

  h1 {
    font-size: 40px;
    font-weight: 700;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
  }

  h3 {
    font-size: 30px;
    font-weight: 300;
  }

  h4 {
    font-size: 26px;
    font-weight: 300;
  }

  h5 {
    font-size: 15px;
    font-weight: 300;
  }

  h6 {
    font-size: 15px;
    font-weight: 300;
  }

  p {
    font-size: 17px;
    font-weight: 300;
  }
  ul {
    font-family: ${fonts.primary};
    font-size: 17px;
    font-weight: 200;
    line-height: 1.8;
    color: ${colors.gray300};
  }

  blockquote {
    margin: 30px 0px;
    font-size: 17px;
    font-weight: 300;
    font-style: italic;
  }

  pre {
    display: block;
    background-color: ${colors.gray300};
    border-radius: 2px;
  }

  pre,
  code {
    max-width: 100%;
    padding: 30px;
    color: ${colors.white};
    overflow: auto;
  }

  iframe {
    display: block;
    width: 100%;
    height: 500px;
    margin: 30px 0;
  }

  @media screen and (max-width: 768px) {
    iframe {
      height: 300px;
    }
  }

  @media screen and (max-width: 520px) {
    iframe {
      height: 250px;
    }
  }

  @media screen and (max-width: 420px) {
    iframe {
      height: 200px;
    }
  }
`;
